






















































import { DataSourceBank } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseBank } from "@/models/interface/master.interface";
import { masterServices } from "@/services/master.service";
import { debounceProcess } from "@/helpers/debounce";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import store from "@/store";
import Vue from "vue";
import { numericOnly } from "@/validator/globalvalidator";

export default Vue.extend({
  props: ["activeTabPane"],
  name: "bankDetails",
  watch: {
    "$store.state.contactStore.bankDataList": {
      immediate: true,
      deep: true,
      handler() {
        this.totalData = this.dataSource.length;
      },
    },
  },
  data() {
    this.handleSearchSelectTable = debounceProcess(
      this.handleSearchSelectTable,
      400
    );
    return {
      limit: 10 as number,
      page: 1 as number,
      totalData: 1 as number,
      loadingTable: false as boolean,
      defaultPagination: false as boolean,
      selectedRowKeys: [] as number[],
      dataSource: this.$store.state.contactStore
        .bankDataList as DataSourceBank[],
      dataSourceInUsed: this.$store.state.contactStore
        .bankDataList as DataSourceBank[],
      columns: [
        {
          title: this.$root.$t("lbl_bank_name"),
          dataIndex: "bankName",
          key: "bankName",
          // ellipsis: true,
          scopedSlots: { customRender: "bankName" },
          responsiveColSelect: [
            {
              name: "bankName",
              placeholder: this.$root.$t("lbl_bank_name"),
              style: "width: 100%;",
              disabled: "",
              value: "name",
              options: [] as ResponseBank[],
              loading: false,
            },
          ],
          responsiveColInput: [
            {
              name: "bankBranch",
              placeholder: this.$root.$t("lbl_bank_branch_name"),
              style: "width: 100%;",
              disabled: "",
            },
            {
              name: "bankAccName",
              placeholder: this.$root.$t("lbl_bank_account_name"),
              style: "width: 100%;",
              disabled: "",
            },
            {
              name: "bankAccNumber",
              placeholder: this.$root.$t("lbl_bank_account_number"),
              style: "width: 100%;",
              disabled: "",
            },
          ],
        },
        {
          title: this.$root.$t("lbl_bank_branch_name"),
          dataIndex: "bankBranch",
          key: "bankBranch",
          scopedSlots: { customRender: "bankBranch" },
        },
        {
          title: this.$root.$t("lbl_bank_account_name"),
          dataIndex: "bankAccName",
          key: "bankAccName",
          scopedSlots: { customRender: "bankAccName" },
        },
        {
          title: this.$root.$t("lbl_bank_account_number"),
          dataIndex: "bankAccNumber",
          key: "bankAccNumber",
          scopedSlots: { customRender: "bankAccNumber" },
        },
      ],
    };
  },
  methods: {
    filterDataSourceForPaging() {
      // 1 * 10 = 10 => 1 - 10 => this.limit * this.page - this.limit + 1
      // 2 * 10 = 20 => 11 - 20
      // 3 * 10 = 30 => 21 - 30
      this.dataSourceInUsed = this.dataSource
        .map((dataMap, index) => {
          return {
            ...dataMap,
            key: index,
          };
        })
        .filter((dataFilter) => {
          return (
            dataFilter.key + 1 >= this.limit * this.page - this.limit + 1 &&
            dataFilter.key + 1 <= this.limit * this.page
          );
        });
    },
    commitToStore() {
      this.totalData = this.dataSource.length;
      store.commit(
        `contactStore/${Mutations.SET_BANK_DATA_LIST}`,
        this.dataSource
      );
      this.filterDataSourceForPaging();
    },
    filterDuplicate() {
      const findColumn = this.columns.find(
        (column) => column.responsiveColSelect
      )?.responsiveColSelect;
      if (findColumn) {
        findColumn[0].options = findColumn[0].options.filter(
          (valueFilter, index, self) =>
            index === self.findIndex((t) => t.id === valueFilter.id)
        );
      }
    },
    handleSearchSelectTable(value) {
      let params = {
        // page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (value)
        params.search = `name~*${value}*_OR_code~*${value}*_OR_secureId~*${value}*`;
      if (!value) params.page = 0;
      const findColumn = this.columns.find(
        (column) => column.responsiveColSelect
      )?.responsiveColSelect;
      if (findColumn) {
        findColumn[0].loading = true;
      }
      masterServices
        .listMasterBank(params)
        .then((data) => {
          if (findColumn) {
            findColumn[0].options = data.data;
            this.filterDuplicate();
            this.columns = this.columns.slice();
          }
        })
        .finally(() => {
          if (findColumn) findColumn[0].loading = false;
        });
    },
    getTableBankForUpdate(value) {
      const params = {} as RequestQueryParamsModel;
      if (value)
        params.search = `name~*${value}*_OR_code~*${value}*_OR_secureId~*${value}*`;
      const findColumn = this.columns.find(
        (column) => column.responsiveColSelect
      )?.responsiveColSelect;
      if (findColumn) {
        findColumn[0].loading = true;
      }
      masterServices
        .listMasterBank(params)
        .then((data) => {
          if (findColumn) {
            findColumn[0].options = [...findColumn[0].options, ...data.data];
            this.columns = this.columns.slice();
            this.filterDuplicate();
            // kondisi jika option bank kosong
            if (findColumn[0].options.length === 0) {
              this.handleSearchSelectTable("");
            }
          }
        })
        .finally(() => {
          if (findColumn) findColumn[0].loading = false;
        });
    },
    handleSelect(value, key, name) {
      this.dataSource[key][name] = value;
      this.dataSource = this.dataSource.slice();
      this.commitToStore();
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    showConfirmation() {
      this.$confirm({
        title: `Do you want to delete these items?`,
        content: `Total: ${this.selectedRowKeys.length} items will be deleted.`,
        onOk: () => {
          this.handleDeleteRow();
        },
        onCancel() {
          return;
        },
      });
    },
    handleDeleteRow() {
      // delete row
      this.dataSource = this.dataSource.filter((data) => {
        return !this.selectedRowKeys.includes(data.key);
      });
      // kembalikan key sesuai urutan indexnya
      this.dataSource.forEach((data, index) => (data.key = index));
      this.dataSource = this.dataSource.slice();
      this.selectedRowKeys = [];
      this.page = 1;
      this.commitToStore();
    },
    handleAddRow() {
      this.dataSource = [
        ...this.dataSource,
        {
          bankName: null,
          bankBranch: null,
          bankAccName: null,
          bankAccNumber: null,
          key: this.dataSource.length,
          no: this.dataSource.length,
        },
      ];
      this.commitToStore();
    },
    handleInput(value, key, objectColInput, columnName, event) {
      if (columnName === "bankAccNumber" && event === "onBlur") {
        this.dataSource[key][columnName] = numericOnly(value);
        this.dataSource = this.dataSource.slice();
      } else {
        this.dataSource[key][objectColInput.name] = value;
        this.dataSource = this.dataSource.slice();
      }
      this.commitToStore();
    },
    responsePageSizeChange(response) {
      this.limit = response.size;
      this.page = 1;
      this.filterDataSourceForPaging();
    },
    responseCurrentPageChange(response) {
      this.page = response.page;
      this.filterDataSourceForPaging();
    },
  },
  created() {
    const idContact = this.$route.params.id;
    const findColumn = this.columns.find(
      (column) => column.responsiveColSelect
    )?.responsiveColSelect;
    if (findColumn && this.activeTabPane === "Bank Details" && !idContact) {
      if (findColumn[0].options.length < 1) {
        this.handleSearchSelectTable("");
      }
    }
    if (
      idContact &&
      this.dataSource.length > 0 &&
      this.activeTabPane === "Bank Details"
    ) {
      this.dataSource.forEach((data) => {
        this.getTableBankForUpdate(data.bankName);
      });
    }
    if (
      idContact &&
      this.dataSource.length === 0 &&
      this.activeTabPane === "Bank Details"
    ) {
      this.handleSearchSelectTable("");
    }
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
